import { menuLeftTabs } from '../../config';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import PermissionStorage from "../../../../services/permission/PermissionStorage";
import {DriverInfo, UserInfo} from "../../interface";

export const initialState: ContainerState = {
    loadUser: false,
    loadDriver: false,
    loadCar: false,
    loadAccess: false,
    loadEditCreate: false,
    showBottomBlock: false,
    leftMenuItem: menuLeftTabs[0].value,
    rightMenuItem: menuLeftTabs[0].menuRightTab,
    userTypeRight: "user.view",
    driverTypeRight: "driver.empty",
    successCreateUser: false,
    listUser: [],
    selectUser: {},
    errorValid: {},
    selectDriver: {},
    listAccessCar: [],
    listAccessDriver: [],
    listDriver: [],
    tableRightView: [],
    tableRightChange: [],
    tableRightElementTrack: [],
    tableRightControl: [],
    linkedVehicleListByDriverId: [],
    userListSort: "name"
};

function settingsReducer(
    state: ContainerState = initialState,
    action: ContainerActions,
): ContainerState {
    switch (action.type) {
        case ActionTypes.SET_SORT_USER_LIST:
            return {
                ...state,
                userListSort: action.payload,
            };
        case ActionTypes.UPDATE_LOAD:
            return {
                ...state,
                loadEditCreate: action.payload,
            };
        case ActionTypes.CREATE_UPDATE_USER:
        case ActionTypes.UPDATE_ACCESS_USER:
            return {
                ...state,
                loadEditCreate: true,
            };
        case ActionTypes.GET_LIST_USER:
            return {
                ...state,
                loadUser: false,
            };
        case ActionTypes.GET_INFO_ACCESS_USER:
            return {
                ...state,
                loadDriver: false,
                loadCar: false,
                loadAccess: false,
            };
        case ActionTypes.CLEAR_STATE: {
            const reset: any = {};
            for (const i in initialState) {
                if (state[i as keyof typeof state]) {
                    reset[i as keyof typeof reset] = initialState[i as keyof typeof initialState]
                }
            }
            return {
                ...reset
            }
        }
        case ActionTypes.SHOW_BOTTOM_BLOCK:
            return {
                ...state,
                showBottomBlock: action.payload,
            };
        case ActionTypes.SWITCH_LEFT_MENU_ITEM: {
            let index = 0;
            menuLeftTabs.some((item, i) => { if (item.value === action.payload) return (index = i); });
            const rightMenuItem = menuLeftTabs[index].menuRightTab;
            return {
                ...state,
                leftMenuItem: action.payload,
                showBottomBlock: false,
                rightMenuItem
            };
        }
        case ActionTypes.SWITCH_RIGHT_MENU_ITEM: {
            const _showBottomBlock = action.payload === "access";
            return {
                ...state,
                showBottomBlock: _showBottomBlock,
                rightMenuItem: action.payload,
            };
        }
        case ActionTypes.UPDATE_USER_INFO: {
            const { id, value } = action.payload;
            const { selectUser } = state;
            selectUser[id as keyof UserInfo] = value;
            if (state.errorValid[id as keyof UserInfo]) {
                delete state.errorValid[id as keyof UserInfo];
            }
            return {
                ...state,
                selectUser: Object.assign({}, selectUser),
            };
        }
        case ActionTypes.UPDATE_DRIVER_INFO: {
            const { selectDriver } = state;
            selectDriver[action.payload.id as keyof DriverInfo] = action.payload.value;
            if (state.errorValid[action.payload.id as keyof DriverInfo]) {
                delete state.errorValid[action.payload.id as keyof DriverInfo];
            }
            return {
                ...state,
                selectDriver: Object.assign({}, selectDriver),
            };
        }
        case ActionTypes.UPDATE_LIST_USER: {
            const userTypeRight = action.payload?.length ? "user.view" : "user.add";
            let user = !state.selectUser?.id && action.payload?.length ? action.payload[0] : initialState.selectUser;
            if (state.selectUser?.id && action.payload?.length) {
                user = action.payload.filter((item: any) => item.id === state.selectUser?.id)[0];
            } else if (action.payload?.length && typeof action.meta === "string") {
                user = action.payload.filter((item: any) => item.email === action.meta)[0];
            }
            if (user?.phone?.length) {
                user.phone1 = user?.phone[0];
                if (user?.phone[1]) {
                    user.phone2 = user?.phone[1];
                }
            }
            return {
                ...state,
                listUser: action.payload,
                userTypeRight,
                selectUser: Object.assign({}, user),
                loadUser: true,
                loadEditCreate: initialState.loadEditCreate,
                errorValid: initialState.errorValid,
            };
        }
        case ActionTypes.SELECT_USER: {
            const _user = action.payload;
            if (_user?.phone?.length) {
                _user.phone1 = _user?.phone[0];
                if (_user?.phone[1]) {
                    _user.phone2 = _user?.phone[1];
                }
            }
            return {
                ...state,
                selectUser: Object.assign({}, _user),
                errorValid: initialState.errorValid,
                userTypeRight: "user.view",
                tableRightView: initialState.tableRightView,
                tableRightChange: initialState.tableRightChange,
                tableRightControl: initialState.tableRightControl,
                tableRightElementTrack: initialState.tableRightElementTrack,
                loadAccess: false,
                loadEditCreate: initialState.loadEditCreate
            };
        }
        case ActionTypes.UPDATE_INFO_ACCESS_USER: {
            const { /*listCar,*/ listDriver, rightView, rightChange, rightControl, rightTrack } = action.payload;
            return {
                ...state,
                // listAccessCar: listCar,
                listAccessDriver: listDriver,
                tableRightView: rightView || initialState.tableRightView,
                tableRightChange: rightChange || initialState.tableRightChange,
                tableRightControl: rightControl || initialState.tableRightControl,
                tableRightElementTrack: rightTrack || initialState.tableRightElementTrack,
                loadDriver: true,
                loadCar: true,
                loadAccess: true
            };
        }
        case ActionTypes.SET_USER_CARS: {
            return {
                ...state,
                listAccessCar: action.payload
            };
        }
        case ActionTypes.UPDATE_LIST_DRIVER: {
            const driverTypeRight = action.payload?.length ? "driver.view" : "driver.empty";
            let driver = !state.selectDriver?.id && action.payload?.length ? action.payload[0] : initialState.selectDriver;
            if (state.selectDriver?.id && action.payload?.length) {
                driver = action.payload.filter((item: any) => item.id === state.selectDriver?.id)[0];
            }
            if (driver?.phone?.length) {
                driver.phone1 = driver?.phone[0];
                if (driver?.phone[1]) {
                    driver.phone2 = driver?.phone[1];
                }
            }

            return {
                ...state,
                listDriver: action.payload,
                driverTypeRight: driverTypeRight,
                selectDriver: Object.assign({}, driver),
            }
        }
        case ActionTypes.SET_FULL_ACCESS: {
            const permissions = PermissionStorage.getUserPermissions();
            state.tableRightView.map((item: any) => {
                if (item.privileges[item.code] && permissions.includes(item.code)) {
                    item["privileges" as keyof typeof item][item.code].enabled = true;
                }
                return item;
            });
            state.tableRightControl.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = true;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightElementTrack.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = true;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightChange.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege] && permissions.includes(item.module)) {
                        item["privileges" as keyof typeof item][privilege].enabled = true;
                    }
                    return privilege;
                });
                Object.assign(item, { disabled: false });
                return item;
            });
            return {
                ...state
            }
        }
        case ActionTypes.SET_VIEW_ACCESS: {
            state.tableRightView.map((item: any) => {
                item["privileges" as keyof typeof item][item.code].enabled = !(item.code === "admin" || item.code === "setup");
                return item;
            });
            state.tableRightControl.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = false;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightElementTrack.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = true;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightChange.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = false;
                    }
                    return privilege;
                });
                Object.assign(item, { disabled: false });
                if (item.id === "add_edit_customers" ||
                    item.id === "add_edit_users" ||
                    item.id === "add_edit_drivers" ||
                    item.id === "add_edit_objects" ||
                    item.id === "add_edit_sensors" ||
                    item.id === "add_edit_groups" ||
                    item.id === "add_edit_commands" ||
                    item.id === "add_edit_trackers" ||
                    item.id === "add_edit_tracks" ||
                    item.id === "add_edit_tariffs"
                ) {

                    Object.assign(item, { disabled: true });
                }
                return item;
            });
            return {
                ...state
            }
        }
        case ActionTypes.SELECT_DRIVER: {
            const driverTemp = action.payload;
            if (driverTemp.phone?.length) {
                driverTemp.phone1 = driverTemp.phone[0];
                if (driverTemp.phone.length > 1) driverTemp.phone2 = driverTemp.phone[1];
            }
            return {
                ...state,
                errorValid: initialState.errorValid,
                selectDriver: Object.assign({}, driverTemp),
                driverTypeRight: "driver.view"
            }
        }
        case ActionTypes.EDIT_DRIVER: {
            const firstDriver = !state.selectDriver?.id && state.listDriver?.length ? state.listDriver[0] : state.selectDriver;
            const dtypeRight = state.listDriver?.length ? "driver.edit" : "driver.add";
            return {
                ...state,
                driverTypeRight: dtypeRight,
                selectDriver: Object.assign({}, firstDriver),
            }
        }
        case ActionTypes.ADD_DRIVER:
            return {
                ...state,
                selectDriver: {},
                driverTypeRight: "driver.add"
            };
        case ActionTypes.ADD_USER:
            return {
                ...state,
                selectUser: {},
                tableRightView: initialState.tableRightView,
                tableRightChange: initialState.tableRightChange,
                tableRightControl: initialState.tableRightControl,
                tableRightElementTrack: initialState.tableRightElementTrack,
                userTypeRight: "user.add",
                loadEditCreate: initialState.loadEditCreate,
            };
        case ActionTypes.UPDATE_ACCESS: {
            const { tableName, checked, key, idChecked } = action.payload;
            switch (tableName) {
                case "tableRightChange":
                    state.tableRightChange[key as keyof typeof state.tableRightChange]["privileges"][idChecked]["enabled"] = checked;
                    break;
                case "tableRightControl":
                    state.tableRightControl[key as keyof typeof state.tableRightControl]["privileges"][idChecked]["enabled"] = checked;
                    break;
                case "tableRightElementTrack":
                    state.tableRightElementTrack[key as keyof typeof state.tableRightElementTrack]["privileges"][idChecked]["enabled"] = checked;
                    break;
                case "tableRightView":
                    state.tableRightView[key as keyof typeof state.tableRightView]["privileges"][idChecked]["enabled"] = checked;
                    break;
            }
            return {
                ...state
            }
        }
        case ActionTypes.EDIT_USER: {
            const firstUser = !state.selectUser?.id && state.listUser?.length ? state.listUser[0] : state.selectUser;
            const typeRight = state.listUser?.length ? "user.edit" : "user.add";
            if (firstUser?.phone?.length) {
                firstUser.phone1 = firstUser?.phone[0];
                if (firstUser?.phone[1]) {
                    firstUser.phone2 = firstUser?.phone[1];
                }
            }
            return {
                ...state,
                userTypeRight: typeRight,
                selectUser: Object.assign({}, firstUser),
                loadEditCreate: initialState.loadEditCreate,
                errorValid: initialState.errorValid,
            }
        }
        // case ActionTypes.SELECT_CAR: {
        //     if (action.payload.key === "all") {
        //         state.listAccessCar.map((car: any) => {
        //             return Object.assign(car, { checked: action.payload.checked });
        //         });
        //     } else {
        //         state.listAccessCar[action.payload.key as keyof typeof state.listAccessCar]["checked"] = action.payload.checked;
        //     }
        //
        //     return {
        //         ...state
        //     }
        // }
        case ActionTypes.SELECT_DRIVER_ACCESS: {
            if (action.payload.key === "all") {
                state.listAccessDriver.map((driver: any) => {
                    return Object.assign(driver, { checked: action.payload.checked });
                });
            } else {
                state.listAccessDriver[action.payload.key as keyof typeof state.listAccessDriver]["checked"] = action.payload.checked;
            }

            return {
                ...state
            }
        }
        case ActionTypes.RESPONSE_CREATE_USER:
            return {
                ...state,
                successCreateUser: action.payload,
            };
        case ActionTypes.UPDATE_LINKED_VEHICLE_LIST_BY_DRIVER_ID:
            return {
                ...state,
                linkedVehicleListByDriverId: action.payload
            };
        case ActionTypes.VALID_CREATE_EDIT_USER:
            return {
                ...state,
                errorValid: Object.assign({}, action.payload)
            };
        case ActionTypes.UPDATE_TYPE_RIGHT_USER: {
            const _firstUser = !state.selectUser?.id && state.listUser?.length ? state.listUser[0] : state.selectUser;
            return {
                ...state,
                userTypeRight: action.payload,
                selectUser: Object.assign({}, _firstUser),
                errorValid: initialState.errorValid,
            };
        }
        case ActionTypes.UPDATE_TYPE_RIGHT_DRIVER: {
            let _firstDriver = !state.selectDriver?.id && state.listDriver?.length ? state.listDriver[0] : state.selectDriver;
            const _driverTypeRight = !_firstDriver.id ? "driver.empty" : action.payload;
            _firstDriver = _driverTypeRight === "driver.empty" ? initialState.selectDriver : _firstDriver;
            return {
                ...state,
                driverTypeRight: _driverTypeRight,
                selectDriver: Object.assign({}, _firstDriver),
                errorValid: initialState.errorValid,
            };
        }
        default:
            return state;
    }
}

export default settingsReducer;