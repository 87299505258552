import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

export default {
    init(): void {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            integrations: [new BrowserTracing(), new Sentry.Replay(
                {
                    maskAllText: false,
                    blockAllMedia: false
                }
            )],
            environment: process.env.REACT_APP_ENV,
            tracesSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            release: process.env.RELEASE,
            ignoreErrors: ["ChunkLoadError", /Loading chunk [\d]+ failed/],
        });
    }
}