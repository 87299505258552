import React, {useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import {SnackbarContent} from "@mui/material";
import {createStructuredSelector} from "reselect";
import {
    makeSelectServerConnectionStatus
} from "../../shared/selectors";
import {useDispatch, useSelector} from "react-redux";
import {sseSubscribe} from "../../shared/actions";

const stateSelector = createStructuredSelector({
    SERVER_CONNECTION_STATUS: makeSelectServerConnectionStatus()
});

const InterruptedConnectionNotification = () => {
    const {SERVER_CONNECTION_STATUS} = useSelector(stateSelector);

    const dispatch = useDispatch();

    const [isInit, setIsInit] = useState(false);
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(2);
    const [step, setStep] = useState(0);
    const [status, setStatus] = useState(0);

    useEffect(() => {
        setStatus(SERVER_CONNECTION_STATUS);
        if ((!SERVER_CONNECTION_STATUS && isInit) || SERVER_CONNECTION_STATUS === 2) {
            setTimeout(() => {
                if (!status || status === 2) {
                    setOpen(true);
                }
            }, 500);
        } else {
            setIsInit(true);
            setOpen(false);
        }
    }, [SERVER_CONNECTION_STATUS]);

    useEffect(() => {
        let interval: any;
        if (open) {
            interval = setInterval(() => {
                if (count) setCount(count - 1);
                else {
                    dispatch(sseSubscribe());
                    updateCounter();
                }

            }, 1000);
        }
        return () => clearInterval(interval);
    }, [count, open]);

    const updateCounter = () => {
        if (!step) {
            setStep(step + 1);
            setCount(5);
        } else if (step === 1) {
            setStep(step + 1);
            setCount(30);
        } else setCount(30);
    };

    const handleClick = () => {
        dispatch(sseSubscribe());
        updateCounter();
    };

    return (
        <div>
            <Snackbar
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <SnackbarContent
                    sx={{backgroundColor: '#ff6500!important', marginTop: '-16px', marginRight: '-16px'}}
                    message={
                        <div className="row-alignment-start">
                            <div>Connection interrupted. Repeat after {count} seconds.</div>
                            <div style={{textDecoration: 'underline', cursor: 'pointer', padding: '0 12px'}} onClick={handleClick}>Try now</div>
                        </div>
                    }
                />
            </Snackbar>
        </div>
    );
};

export default InterruptedConnectionNotification;