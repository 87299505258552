import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import {Vehicle} from "../../../../models/Vehicle";
import {Sensor} from "../../../../models/Sensor";

export const initialState: ContainerState = {
    vehicleList: [],
    vehicle: {},
    // vehicleId: undefined,
    listVehicleTypes: [],
    listVehicleKinds: [],
    vehicleData: {},
    // listDevice: [],
    vehicle_CRUD_Mode: undefined,
    sensor: {},
    listSensorTypes: [],
    listSensorModels: [],
    listSensorBrands: [],
    vehicleSensors: [],
    vehicleListByUser: [],
    vehicleCounters: undefined
};

function settingsObjectsReducer(
    state: ContainerState = initialState,
    action: ContainerActions
): ContainerState {
    switch (action.type) {
        case ActionTypes.SET_VEHICLE_LIST:
            return {
                ...state,
                vehicleList: action.payload
            };
        case ActionTypes.UPDATE_VEHICLE:
            return {
                ...state,
                vehicle: action.payload
            };
        case ActionTypes.UPDATE_VEHICLE_BY_ATTRIBUTE: {
            const { k, attr } = action.payload;
            const { vehicle } = state;
            if (k === 'tripCounterId' || k === 'chassisNumber' || k === 'enginePower'
                || k === 'engineCapacity' || k === 'fuelType' || k === 'tankVolume' || k === 'voltage'
                || k === 'inspectionExpireDate' || k === 'insuranceExpireDate' || k === 'inspectionMileage'
                || k === 'inspectionMileageEvery'
                || k === 'serviceStartDate' || k === 'trailer') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.vehicle) vehicle.attributes.vehicle = {};
                if (attr) vehicle.attributes.vehicle[k as keyof any] = attr;
                else delete vehicle.attributes.vehicle[k as keyof any];
            } else if (k === 'mileageType' || k === 'engineHoursType' || k === 'networkTraffic'
                || k === 'mileage' || k === 'engineHours') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.counters) vehicle.attributes.counters = {};
                if (attr || attr === 0) vehicle.attributes.counters[k as keyof any] = attr;
                else delete vehicle.attributes.counters[k as keyof any];
            } else if (k === 'sensors') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (attr) vehicle.attributes.sensors = attr;
                else delete vehicle.attributes.orders[k as keyof any];
            } else if (k === 'insuranceReminderDays' || k === 'insuranceReminderDaysChecked' ||
                k === 'inspectionEndReminderDays' || k === 'inspectionEndReminderDaysChecked' ||
                k === 'inspectionReminderMileage' || k === 'inspectionReminderMileageChecked' ||
                k === 'fuelSummerCity' || k === 'fuelWinterCity'
                || k === 'fuelSummerHighway' || k === 'fuelWinterHighway') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.control) vehicle.attributes.control = {};
                vehicle.attributes.control[k as keyof any] = attr;
            } else if (k === 'localityMaxSpeed' || k === 'maxSpeed' || k === 'stopTime' ||
                k === 'stopTime' || k === 'dayEngineHours') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.trips) vehicle.attributes.trips = {};
                if (attr) vehicle.attributes.trips[k as keyof any] = attr;
                else delete vehicle.attributes.trips[k as keyof any];
            } else if (k === 'trailer.model' || k === 'trailer.manufactureYear' || k === 'trailer.serviceStartDate' ||
                k === 'trailer.type' || k === 'trailer.licensePlate' || k === 'trailer.vin' ||
                k === 'trailer.chassisNumber' || k === 'trailer.description' || k === 'trailer.inventoryNumber' ||
                k === 'trailer.inspectionExpireDate') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.trailer) vehicle.attributes.trailer = {};
                if (attr) vehicle.attributes.trailer[k.split('.')[1] as keyof any] = attr;
                else delete vehicle.attributes.trailer[k.split('.')[1] as keyof any];
            } else if (k === 'observableObject.kind' || k === 'observableObject.fullName' ||
                k === 'observableObject.phone' || k === 'observableObject.birthday' ||
                k === 'observableObject.addressResidence' || k === 'observableObject.contract' ||
                k === 'observableObject.description' || k === 'observableObject.responsibleFullName' ||
                k === 'observableObject.responsiblePhone' || k === 'observableObject.responsibleDescription' ||
                k === 'observableObject.hasResponsible') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.data) vehicle.attributes.data = {};
                if (!vehicle.attributes.data.observableObject) vehicle.attributes.data.observableObject = {};
                if (attr) vehicle.attributes.data.observableObject[k.split('.')[1] as keyof any] = attr;
                else delete vehicle.attributes.data.observableObject[k.split('.')[1] as keyof any];
            } else if (k === 'courier.kind' || k === 'courier.fullName' || k === 'courier.phone' ||
                k === 'courier.birthday' || k === 'courier.passport' || k === 'courier.addressResidence' ||
                k === 'courier.addressRegistration' || k === 'courier.contract' || k === 'courier.contractStart' ||
                k === 'courier.contractEnd' || k === 'courier.description' ||
                k === 'courier.vehicleType' || k === 'courier.registrationNumber' ||
                k === 'courier.driverLicense' || k === 'courier.vehicleDescription' || k === 'courier.hasCourierVehicle') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.data) vehicle.attributes.data = {};
                if (!vehicle.attributes.data.courier) vehicle.attributes.data.courier = {};
                if (attr) vehicle.attributes.data.courier[k.split('.')[1] as keyof any] = attr;
                else delete vehicle.attributes.data.courier[k.split('.')[1] as keyof any];
            } else if (k === 'contractEndReminderDaysChecked' || k === 'contractEndReminderDays' ||
                k === 'workDayHours') {
                if (!vehicle.attributes) vehicle.attributes = {};
                if (!vehicle.attributes.data) vehicle.attributes.data = {};
                if (attr || attr === 0) vehicle.attributes.data[k as keyof any] = attr;
                else delete vehicle.attributes.data[k as keyof any];
            }else if (k === 'tags' || k === 'group') {
                delete vehicle[k as keyof any];
            } else {
                vehicle[k as keyof Vehicle] = attr;
            }
            return {
                ...state,
                vehicle: { ...vehicle }
            };
        }
        case ActionTypes.UPDATE_LIST_VEHICLE_TYPES:
            return {
                ...state,
                listVehicleTypes: action.payload
            };
        case ActionTypes.UPDATE_LIST_VEHICLE_KINDS:
            return {
                ...state,
                listVehicleKinds: action.payload
            };
        // case ActionTypes.UPDATE_LIST_DEVICE:
        //     return {
        //         ...state,
        //         listDevice: action.payload
        //     };
        // case ActionTypes.SET_VEHICLE_ID:
        //     return {
        //         ...state,
        //         vehicleId: action.payload,
        //         vehicle_CRUD_Mode: "vehicle.view"
        //     };
        case ActionTypes.SET_VEHICLE_CRUD_MODE:
            return {
                ...state,
                vehicle_CRUD_Mode: action.payload
            };
        case ActionTypes.SET_SENSOR:
            return {
                ...state,
                sensor: action.payload
            };
        case ActionTypes.UPDATE_SENSOR_BY_ATTRIBUTE: {
            const { keySensor, attrSensor } = action.payload;
            const { sensor } = state;
            if (keySensor === 'fuelCalibration') {
                if (!sensor.attributes) sensor.attributes = {};
                if (attrSensor) sensor.attributes[keySensor as keyof any] = attrSensor;
                else delete sensor.attributes[keySensor as keyof any];
            } else if (sensor) {
                if (attrSensor) sensor[keySensor as keyof Sensor] = attrSensor;
                else sensor[keySensor as keyof any] = null;
            }
            return {
                ...state,
                sensor: { ...sensor }
            };
        }
        case ActionTypes.SET_LIST_SENSOR_TYPES:
            return {
                ...state,
                listSensorTypes: action.payload
            };
        case ActionTypes.SET_LIST_SENSOR_MODELS:
            return {
                ...state,
                listSensorModels: action.payload
            };
        case ActionTypes.SET_LIST_SENSOR_BRANDS:
            return {
                ...state,
                listSensorBrands: action.payload
            };
        case ActionTypes.SET_VEHICLE_SENSORS:
            return {
                ...state,
                vehicleSensors: action.payload
            };
        case ActionTypes.SET_VEHICLE_LIST_BY_USER:
            return {
                ...state,
                vehicleListByUser: action.payload
            };
        case ActionTypes.SET_VEHICLE_COUNTERS:
            return {
                ...state,
                vehicleCounters: action.payload
            };
        default:
            return state;
    }
}

export default settingsObjectsReducer;