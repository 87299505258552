import ActionTypes from "./constants";
import { ContainerActions, ContainerState } from "./types";
import {User} from "../../../models/User";

// The initial state of the App
export const initialState: ContainerState = {
    loading: false,
    leftTab: "object",
    rightTab: "start_tab",
    reportsFormat: "report",
    listDriver: [],
    checkedVehicles: [],
    selectedDrivers: [],
    dateFrame: {
        date: {
            from: new Date(),
            to: new Date(),
        },
        string: {
            from: "xx.xx.xxxx xx:xx",
            to: "xx.xx.xxxx xx:xx",
        },
    },
    reportTypes: [],
    reportsList: [],
    graphTypes: [],
    graphList: [],
    isOpenInfoModal: false,
    isBuildingReports: false,
    reportsParams: {},
    pdfReportParams: undefined,
    periodicReports: [],
    periodicReportTypes: [],
    report_CRUD_Mode: "view"
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function reportsReducer(
    state: ContainerState = initialState,
    action: ContainerActions
): ContainerState {
    switch (action.type) {
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case ActionTypes.SWITCH_LEFT_MENU_ITEM:
            return {
                ...state,
                leftTab: action.payload,
            };
        case ActionTypes.SWITCH_RIGHT_MENU_ITEM:
            return {
                ...state,
                rightTab: action.payload,
            };
        case ActionTypes.SWITCH_REPORTS_FORMAT:
            return {
                ...state,
                reportsFormat: action.payload,
            };
        case ActionTypes.SET_DRIVERS:
            return {
                ...state,
                listDriver: action.payload,
            };
        case ActionTypes.SET_VEHICLES:
            return {
                ...state,
                checkedVehicles: action.payload,
            };
        case ActionTypes.SET_DATE_FRAME:
            return {
                ...state,
                dateFrame: action.payload,
            };
        case ActionTypes.SET_REPORT_TYPES:
            return {
                ...state,
                reportTypes: action.payload,
            };
        case ActionTypes.SET_SELECTED_DRIVERS:
            return {
                ...state,
                selectedDrivers: action.payload,
            };
        case ActionTypes.SET_REPORTS_LIST:
            return {
                ...state,
                reportsList: [...state.reportsList, action.payload],
            };
        case ActionTypes.REMOVE_REPORTS_ITEM_BY_VALUE:
            return {
                ...state,
                reportsList: state.reportsList.filter(
                    (report: { value: string }) =>
                        report.value !== action.payload
                ),
                graphList: state.graphList.filter(
                    (graph: { value: string }) =>
                        graph.value !== action.payload
                )
            };
        case ActionTypes.SET_GRAPH_TYPES:
            return {
                ...state,
                graphTypes: action.payload,
            };
        case ActionTypes.SET_GRAPH_LIST:
            return {
                ...state,
                graphList: [...state.graphList, action.payload],
            };
        case ActionTypes.SET_IS_OPEN_INFO_MODAL:
            return {
                ...state,
                isOpenInfoModal: action.payload,
            };
        case ActionTypes.SET_IS_BUILDING_REPORTS:
            return {
                ...state,
                isBuildingReports: action.payload,
            };
        case ActionTypes.ADD_REPORT_PARAMS_TO_MAP: {
            const { key, value } = action.payload;
            const { reportsParams } = state;
            reportsParams[key as keyof any] = value;
            if (reportsParams) {
                reportsParams[key as keyof User] = value;
            }
            return {
                ...state,
                reportsParams: reportsParams ? { ...reportsParams } : {}
            };
        }
        case ActionTypes.SET_PDF_REPORT_PARAMS:
            return {
                ...state,
                pdfReportParams: action.payload,
            };
        case ActionTypes.SET_PERIODIC_REPORTS:
            return {
                ...state,
                periodicReports: action.payload,
            };
        case ActionTypes.SET_PERIODIC_REPORT_TYPES:
            return {
                ...state,
                periodicReportTypes: action.payload,
            };
        case ActionTypes.SET_REPORT_CRUD_MODE:
            return {
                ...state,
                report_CRUD_Mode: action.payload,
            };
        default:
            return state;
    }
}

export default reportsReducer;
